.loyal__app__container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;

  .loyal__app__inner {
    display: flex;
    transition: transform 1s cubic-bezier(1, 0, .5, .8);
    transform: translateX(-100vw);

    .loyal__app__page {
      flex: 0 0 100vw;
      width: 100vw;
      height: calc(var(--vh, 1vh) * 100);
      overflow: auto;
    }
  }
}

.mobile__navigation {
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  padding-bottom: 4rem;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  // background: white;
  // border-top: 1px solid #eee;
  padding: 2rem;
  transform: translateY(200%);
  transition: all .5s ease;

  .loyal__app__button {
    border-color: black;

    .loyal__app__button__arrow:after {
      border-color: black;
    }

    .loyal__app__button__inner {
      background: white;
    }

    .loyal__app__button__word {
      color: black;
    }
  }

  &.show {
    transform: translateY(0);
  }
}