.loyal__app__sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 4rem;
  height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;
  background: #fff;
  border-right: 1px #edf2f7 solid;
  transition: all .3s ease;
  animation: loyal__app__sidebar__show 1s ease-in-out forwards;
  z-index: 1000;

  &__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    height: 33.3%;
    box-sizing: border-box;

    &:first-child {
      align-items: flex-start;

      >div {
        margin: 3rem auto 0 auto;

        .loyal__app__sidebar__word {
          align-self: flex-end;
        }
      }
    }

    &:last-child {
      align-items: flex-end;

      >div {
        margin: 0 auto 3rem auto;
      }
    }
  }

  &__label {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    font-weight: bold;
    height: inherit;
    width: inherit;
    font-size: .75rem;
    letter-spacing: .15rem;
    transform: rotate(180deg);
    user-select: none;
    box-sizing: border-box;
  }

  &__letter {
    text-transform: uppercase;
    writing-mode: vertical-lr;
    min-height: 5px;
  }

  &__word {
    position: absolute;
    line-height: 4rem;
    overflow: hidden;

    &.show {
      .loyal__app__sidebar__letter {
        opacity: 0;
        transform: translateX(4rem);
        animation: loyal__app__sidebar__title__show 1s forwards cubic-bezier(1, 0, .5, .8);
      }
    }

    &.hide {
      animation: loyal__app__sidebar__title__opacity__hide 1s forwards cubic-bezier(1, 0, .5, .8);

      .loyal__app__sidebar__letter {
        animation: loyal__app__sidebar__title__hide 1s forwards cubic-bezier(1, 0, .5, .8);
      }
    }

    &.contact {
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 20px;
        width: 1px;
        height: 100%;
        transform: scaleY(0);
        transform-origin: bottom;
        mix-blend-mode: difference;
        background: black;
        transition: all 1s ease;
      }

      &:hover:after {
        transform: scaleY(1);
        transform-origin: top;
      }
    }
  }

  &__menu {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;

    &__dot {
      margin: 3px;
      width: 5px;
      height: 5px;
      border-radius: 1.5px;
      background: #1a1a1a;
      transition: all .3s ease;
    }

    &__animate {
      position: relative;
      width: 5px;
      height: 5px;
      margin: 3px;
      transition: all .3s ease;

      .loyal__app__sidebar__menu__dot {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
      }
    }

    &:hover:not(.open) {
      .loyal__app__sidebar__menu__animate {
        &:first-child {
          transform: translateY(-5px);
        }

        &:last-child {
          transform: translateY(5px);
        }
      }
    }

    &.open {
      .loyal__app__sidebar__menu__animate {
        &:first-child {
          .loyal__app__sidebar__menu__dot {
            &:first-child {
              transform: translate(-7px, 4px);
            }

            &:last-child {
              transform: translate(7px, 4px);
            }
          }
        }

        &:last-child {
          .loyal__app__sidebar__menu__dot {
            &:first-child {
              transform: translate(-7px, -4px);
            }

            &:last-child {
              transform: translate(7px, -4px);
            }
          }
        }
      }
    }
  }
}

@keyframes loyal__app__sidebar__show {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes loyal__app__sidebar__title__show {
  from {
    transform: translateX(4rem);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes loyal__app__sidebar__title__opacity__hide {
  to {
    opacity: 0;
  }
}

@keyframes loyal__app__sidebar__title__hide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-4rem);
  }
}

@media only screen and (max-width: 600px) {
  .loyal__app__sidebar {
    display: none;
  }
}