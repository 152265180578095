.loyal__app__range {
  position: relative;
  height: 20px;
  margin-top: 10px;

  .loyal__app__range__track {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 4px;
    border-radius: 2px;
    background: rgba(255, 255, 255, .5);
    transition: all .3s ease;
    cursor: pointer;

    &.loyal__app__range__track__1 {
      background: white;
      overflow: hidden;
      z-index: 1;

      &::after {
        content: '';
        top: -100px;
        transform: translateX(100%) rotate(45deg);
        width: 100%;
        height: 200px;
        position: absolute;
        animation: loyal__app__range__track__shinning 3s infinite 3s ease;
        background: linear-gradient(to right, rgba(white, 0) 0%, rgba(white, .8) 50%, rgba(red, 0) 99%, rgba(red, 0) 100%);
      }
    }

    &:hover {
      background: #ddd;

      &.loyal__app__range__track__1 {
        background: white;
      }
    }
  }

  .loyal__app__range__thumb {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 0;
    background: white;
    border: 3px solid white;
    box-sizing: border-box;
    top: 50%;
    transform: translateY(-50%);
    transition: transform .3s ease;
    outline: none;
    cursor: pointer;

    &::before {
      content: attr(data-value);
      position: absolute;
      left: 50%;
      bottom: 25px;
      transform: translate(-50%, 10px);
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      background: white;
      color: #410290;
      box-shadow: #25054d;
      padding: 5px;
      border-radius: 3px 10px 10px 10px;
      opacity: 0;
      transition: all .3s ease;
      z-index: 1;
    }

    &:hover {
      box-shadow: 0 4px 10px #410290;

      &::before {
        opacity: 1;
        transform: translate(-50%, 0);
      }
    }
  }
}

@keyframes loyal__app__range__track__shinning {
  0% {
    transform: translateX(-200%) rotate(45deg);
  }

  100% {
    transform: translateX(200%) rotate(45deg);
  }
}