.loyal__app__text {
  position: relative;
  display: inline-flex;
  overflow: hidden;

  .loyal__app__text__word {
    display: flex;
    flex-wrap: wrap;

    .loyal__app__text__letter {
      font-weight: bold;
      transform: translateY(200%);

      span {
        color: #d30002;
      }
    }
  }

  &.show {
    .loyal__app__text__letter {
      animation: loyal__app__text__show 1s forwards ease;
    }

    &.cursor {

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        transform: scaleX(0);
        transform-origin: right;
        // mix-blend-mode: difference;
        background: black;
        transition: all 1s ease;
      }

      &:hover:after {
        transform: scaleX(1);
        transform-origin: left;
      }

      @media only screen and (max-width: 600px) {
        &:hover:after {
          transform: scaleX(0);
          transform-origin: left;
        }
      }
    }
  }
}

@keyframes loyal__app__text__show {
  from {
    opacity: 0;
    transform: translateY(100%)
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}