.loyal__app__content {
  position: relative;
  width: 100vw;
  padding-left: 4rem;
  box-sizing: border-box;
  min-height: calc(var(--vh, 1vh) * 100);
}

@media only screen and (max-width: 600px) {
  .loyal__app__content {
    padding-left: 0;
    padding-top: 5rem;
    box-sizing: border-box;
  }
}