.loyal__app__checkbox {
  display: inline-block;
  position: relative;
  width: 14px;
  height: 14px;
  background: #fcfff4;
  background: linear-gradient(to bottom, #ffffff 0%, #e9e7f5 40%, #fff 100%);
  border-radius: 4px;
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 7px;
    height: 4px;
    border: 3px solid #333;
    border-radius: 3px;
    border-top: none;
    border-right: none;
    background: transparent;
    opacity: 0;
    transform: rotate(-45deg);
  }

  &:hover::after {
    opacity: .3;
  }

  &.checked:after {
    opacity: 1;
  }

  &.mixed:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 1px;
    background: #333;
    opacity: 1;
  }
}