.loyal__app__cursor {
  position: absolute;
  mix-blend-mode: difference;
  pointer-events: none;
  z-index: 100000000;

  >div {
    position: absolute;
    mix-blend-mode: difference;
    background: white;
    border-radius: 50%;
    pointer-events: none;
    z-index: 100000000;
  }

  &__pointer {
    width: 20px;
    height: 20px;
    transform: translate(-50%, -50%);
    transition: transform .3s ease;
  }

  &__core {
    width: 12px;
    height: 12px;
    border-radius: 30% !important;
    transform: translate(-50%, -50%);
    transition: all .3s ease;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 50%;
      border-radius: 2px;
      background: white;
      mix-blend-mode: difference;
      transition: all .3s ease;
      transform: translate(-50%, -50%);
    }

    &.point {
      width: 22px;
      height: 22px;
      border-radius: 30%;
      transform-origin: center;
      backface-visibility: hidden;
      animation: loyal__app__cursor__point 1.5s forwards ease;
      opacity: 1;
    }
  }

  &__tail {
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
    transition: all .5s ease;
    opacity: 0;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90%;
      height: 90%;
      border-radius: 50%;
      background: white;
      mix-blend-mode: difference;
      transition: all .3s ease;
      transform: translate(-50%, -50%);
    }

    &.point {
      width: 22px;
      height: 22px;
      transform-origin: center;
      backface-visibility: hidden;

      &::after {
        width: 0;
        height: 0;
      }
    }
  }

  &.active {
    .loyal__app__cursor__pointer {
      transition: transform .5s ease;
    }

    .loyal__app__cursor__core {
      width: 0;
      height: 0;

      &:after {
        transform: translate(-50%, -50%) scale(0);
      }
    }

    .loyal__app__cursor__tail {
      width: 0;
      height: 0;

      &::after {
        animation: loyal__app__cursor__zoom 1s infinite ease;
      }
    }
  }


  &.text {
    .loyal__app__cursor__pointer {
      transform: scale(0) !important;
    }

    .loyal__app__cursor__core {
      width: 1px !important;
      height: 15px !important;
      border-radius: 0 !important;
      transform: scale(5) !important;
    }

    .loyal__app__cursor__core {
      width: 0;
      height: 0;

      &:after {
        transform: translate(-50%, -50%) scale(0);
      }
    }
  }

  &.move {
    .loyal__app__cursor__core {
      width: 20px;
      height: 20px;
      border-radius: 30%;
      transition: none;

      &:after {
        border-radius: 3px;
      }
    }

    &:not(.text) {
      .loyal__app__cursor__core {
        animation: loyal__app__cursor__rotate 1s infinite ease;
      }
    }
  }
}

@keyframes loyal__app__cursor__point {
  50% {
    transform: translate(-50%, -50%) rotate(360deg);
  }

  100% {
    transform: translate(-50%, -50%) scale(.5);
  }
}

@-webkit-keyframes loyal__app__cursor__point {
  50% {
    transform: translate(-50%, -50%) rotate(360deg);
  }

  100% {
    transform: translate(-50%, -50%) scale(.5);
  }
}

@keyframes loyal__app__cursor__rotate {
  to {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes loyal__app__cursor__zoom {
  50% {
    transform: translate(-50%, -50%) scale(0);
  }
}

@keyframes loyal__app__cursor__zoom__big {
  50% {
    transform: translate(-50%, -50%) scale(0);
  }
}

@media only screen and (max-width: 600px) {
  .loyal__app__cursor {
    display: none;
  }
}