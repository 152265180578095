@font-face {
  font-family: Gilmer-Regular;
  src: url(../fonts/gilmer-regular.woff2);
}

@font-face {
  font-family: MonumentExtended-Regular;
  src: url(../fonts/MonumentExtended-Regular.otf);
}

@font-face {
  font-family: MonumentExtended-Ultrabold;
  src: url(../fonts/MonumentExtended-Ultrabold.otf);
}