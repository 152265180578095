.loyal__app__analog__clock {
  position: relative;
  background: url(../../assets/images/clock.png);
  background-size: 100%;
}

.loyal__app__analog__clock__body {
  position: relative;
  transform: rotate(-90deg);
}

.seconds,
.minute,
.hour {
  transform: rotate(0deg);
}

.seconds {
  transform: translate(1px, -2px) rotate(calc(var(--current-seconds) * 6deg));
  transform-origin: left;
  background: #d00505;
  width: 42%;
  height: 1px;
}

.minute {
  transform: translate(1px, -1px) rotate(calc(var(--current-minutes) * 6deg));
  transform-origin: left;
  animation-delay: calc(var(--current-seconds) * -1 * 1s);
  background: #000;
  width: 35%;
  height: 2px;
  position: relative;
}

.hour {
  transform: translate(1px, 2px) rotate(calc(var(--current-hours) * 30deg));
  transform-origin: left;
  animation: rotate__hour__hand calc(12 * 60 * 60s) linear infinite;
  animation-delay: calc(calc(var(--current-minutes) * -60 * 1s) + calc(var(--current-seconds) * -1 * 1s));
  background: #000;
  width: 25%;
  height: 3px;
}

.pin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: radial-gradient(black, #333);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
}

@keyframes rotate__second__hand {
  from {
    transform: translate(1px, -2px) rotate(calc(var(--current-seconds) * 6deg));
  }

  to {
    transform: translate(1px, -2px) rotate(calc(var(--current-seconds) * 6deg + 360deg));
  }
}

@keyframes rotate__minute__hand {
  from {
    transform: translate(1px, -1px) rotate(calc(var(--current-minutes) * 6deg));
  }

  to {
    transform: translate(1px, -1px) rotate(calc(var(--current-minutes) * 6deg + 360deg));
  }
}

@keyframes rotate__hour__hand {
  from {
    transform: translate(1px, 2px) rotate(calc(var(--current-hours) * 30deg));
  }

  to {
    transform: translate(1px, 2px) rotate(calc(var(--current-hours) * 30deg + 360deg));
  }
}