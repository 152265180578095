.loyal__app__services {
  padding-left: 100px;

  @media only screen and (max-width: 600px) {
    padding: 2rem;
    padding-bottom: 10rem;
  }

  .title {
    margin-top: 8rem;
    text-align: center;

    @media only screen and (max-width: 600px) {
      margin-top: 2rem;
    }

    >div {
      position: relative;
      overflow: hidden;
    }

    h1 {
      position: relative;
      font-family: MonumentExtended-Regular;
      text-transform: uppercase;
      font-size: 40px;
      line-height: 3rem;
      height: 3rem;
      letter-spacing: .15rem;
      margin: 0;
      color: #3a3a3a;
      transform: translateY(200%);

      @media only screen and (max-width: 600px) {
        font-size: 30px;
      }

      span {
        color: #d30002;
      }
    }

    p {
      width: 900px;
      margin: 2rem auto;
      color: rgb(99, 99, 99);
      font-size: 18px;
      font-weight: bold;
      transform: translateY(200%);

      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }

    &.show {

      h1,
      p {
        animation: text-reveal__animation 1s forwards ease;
      }
    }

    &.hide {

      h1,
      p {
        transform: translateY(0) !important;
      }
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 4rem;
    box-sizing: border-box;
    margin-bottom: 100px;

    @media only screen and (max-width: 600px) {
      padding: 0;
    }

    &.show>div {
      animation: icon-reveal__animation 1s ease forwards;
    }

    >div {
      flex: 0 0 33.3%;
      text-align: center;
      opacity: 0;

      @media only screen and (max-width: 600px) {
        flex: 0 0 100%;
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
        border-radius: 10px;

        img {
          height: 80%;
        }
      }

      span {
        display: block;
        font-weight: bold;
        color: #3a3a3a;
        width: 300px;
        height: 38px;
        margin: 20px auto;
      }
    }
  }
}

@keyframes text-reveal__animation {
  from {
    transform: translateY(200%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes icon-reveal__animation {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}