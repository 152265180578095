.loyal__app__work {
  &__plan {
    position: relative;
    display: flex;
    align-items: center;
    height: calc(var(--vh, 1vh) * 100);
    background: #f8f8f8;

    @media only screen and (max-width: 600px) {
      height: calc(calc(var(--vh, 1vh) * 100) - 5rem);
    }

    &__side {
      width: 256px;
      height: 100%;
      background: #25054d;
      border-right: 1px solid #25054d;

      @media only screen and (max-width: 600px) {
        display: none;
      }
    }

    &__container {
      display: flex;
      align-items: center;
      flex-grow: 1;
      height: calc(var(--vh, 1vh) * 100);
      background-image: url(../../assets/images/laptop-mockup.png);
      background-repeat: no-repeat;
      background-size: 60%;
      background-position: bottom right;

      @media only screen and (max-width: 600px) {
        height: calc(calc(var(--vh, 1vh) * 100) - 5rem);
        background-size: 100%;
      }
    }

    &__content {
      margin-left: 4rem;

      @media only screen and (max-width: 600px) {
        width: 100%;
        margin-left: 1rem;
      }

      .plan {
        font-size: 5rem;
        line-height: 7rem;

        @media only screen and (max-width: 600px) {
          font-size: 2.5rem;
          line-height: 4.5rem;
        }
      }
    }

    .loyal__app__button {
      position: absolute;
      bottom: 60px;
      right: 66px;
      z-index: 1;

      @media only screen and (max-width: 600px) {
        right: unset;
        left: 1rem;
        top: 60%;
        bottom: unset;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .loyal__app__button {
      border-color: black;

      &.scroll {
        &:after {
          display: none;
        }

        .loyal__app__button__arrow:after {
          border-color: black;
        }

        .loyal__app__button__word:first-child {
          display: none;
        }
      }

      .loyal__app__button__word {
        color: black;
      }
    }
  }

  .loyal__app__analog__clock {
    position: absolute;
    top: 5rem;
    right: 20rem;
    width: 140px;
    height: 140px;

    @media only screen and (max-width: 600px) {
      top: 2rem;
      right: 2rem;
    }
  }
}