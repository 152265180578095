.loyal__app__project {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(var(--vh, 1vh) * 100);
  overflow: auto;

  @media only screen and (max-width: 600px) {
    height: calc(100vh - 5rem);
  }

  .loyal__app__project__options {
    position: absolute;
    top: 2rem;
    right: 2rem;

    @media only screen and (max-width: 600px) {
      transform: scale(.9);
    }

    .selector {
      position: absolute;
      top: .5rem;
      left: -1.5rem;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background: white;
      transition: all .3s ease;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50%;
        height: 50%;
        border-radius: 50%;
        background: #25054d;
        transform: translate(-50%, -50%);
        z-index: 2;
      }
    }

    .option {
      font-size: 1.5rem;
      line-height: 2rem;
      color: white;
      opacity: .5;
      cursor: pointer;

      &.active {
        opacity: 1;
        font-weight: bold;
      }

      &:hover {
        font-weight: bold;
      }
    }
  }
}

.loyal__app__project__plan {
  &__step__bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: linear-gradient(45deg, #d19adb, #951bae);
    transition: all .3s ease;
    transform-origin: left;
    z-index: 2;
    animation: loyal__app__project__step__bar__show 1s ease forwards;

    &.hide {
      transform: translateX(-100%) !important;
    }
  }

  &__form {
    position: relative;
    width: 80vw;
    height: 6rem;
    margin: auto;
    margin-top: 5rem;

    @media only screen and (max-width: 600px) {
      width: 90vw;
    }

    input {
      position: absolute;
      bottom: 0;
      width: 100%;
      box-sizing: border-box;
      font-size: 5rem;
      line-height: 6rem;
      color: white;
      background: transparent;
      border: none;
      border-bottom: 1px solid white;
      outline: none;
      opacity: 0;
      visibility: hidden;

      @media only screen and (max-width: 600px) {
        font-size: 3rem;
        line-height: 4rem;
        padding: 0;
      }

      &::placeholder {
        color: rgba(255, 255, 255, .5);
      }
    }

    .description {
      position: absolute;
      bottom: 0;
      width: 100%;
      opacity: 0;
      visibility: hidden;

      textarea {
        border: none;
        width: 100%;
        font-family: Gilmer-Regular, Arial, Helvetica, sans-serif;
        font-size: 60px;
        line-height: 80px;
        height: 80px;
        white-space: pre-wrap;
        height: auto;
        padding-top: 0;
        background: transparent;
        color: white;
        outline: none;
        resize: none;
        background: url(../../assets/images/textarea-line.png);
        background-attachment: local;

        &::placeholder {
          color: rgba(255, 255, 255, .5);
        }

        @media only screen and (max-width: 600px) {
          font-size: 30px;
          line-height: 40px;
          background-size: 40px;
          padding: 0;
          // height: 200px;
        }
      }
    }

    span.title {
      position: absolute;
      top: -40px;
      color: rgba(255, 255, 255, .5);
    }

    .type {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      opacity: 0;
      visibility: hidden;
      color: white;

      .loyal__app__text {
        visibility: hidden !important;
        opacity: .6 !important;

        &:after {
          background: white;
        }

        @media only screen and (max-width: 600px) {
          font-size: 1.5rem;
          line-height: 3rem;
        }
      }

      .active .loyal__app__text {
        opacity: 1 !important;

        &:after {
          transform: scaleX(1);
        }
      }

      >div {
        position: relative;
        font-size: 3rem;
        line-height: 5rem;
        margin-right: 50px;

        @media only screen and (max-width: 600px) {
          font-size: 1.5rem;
          line-height: 3rem;
          margin-right: 30px;
        }

        &:after {
          content: '/';
          position: absolute;
          top: 0;
          right: -40px;
          opacity: .6;

          @media only screen and (max-width: 600px) {
            right: -20px;
          }
        }
      }
    }

    .budget {
      position: absolute;
      bottom: 0;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      color: white;

      span {
        position: absolute;
        font-size: 16px;
        top: -80px;
        color: rgba(255, 255, 255, .5);
      }

      .budget__range {
        display: flex;
        flex-wrap: wrap;
        font-size: 4rem;
        line-height: 5rem;

        @media only screen and (max-width: 600px) {
          font-size: 2rem;
          line-height: 3rem;
        }
      }
    }

    .source {
      opacity: 0;
      visibility: hidden;
    }

    .success {
      opacity: 0;
      visibility: hidden;

      >div {
        font-size: 5rem;
        line-height: 6rem;
        color: white;

        @media only screen and (max-width: 600px) {
          font-size: 3rem;
          line-height: 4rem;
        }
      }
    }

    .show {
      animation: loyal__app__project__input__show 1s ease forwards;

      .loyal__app__text {
        visibility: visible !important;
      }

      &.hide {
        animation: loyal__app__project__input__hide 1s ease forwards;
      }
    }
  }

  &__error {
    margin-top: 5px;
    color: red;
  }

  &__step {
    margin: 10px 0;
    color: white;

    span {
      opacity: .6;
    }
  }

  .button__wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;

    @media only screen and (max-width: 600px) {
      margin-top: 40px;
      justify-content: space-between;

      &.single {
        justify-content: flex-end;
      }
    }

    .loyal__app__button {
      margin-left: 10px;

      @media only screen and (max-width: 600px) {
        margin: 0;
      }
    }
  }

  .button__wrapper__single {
    position: relative;
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    visibility: hidden;

    &.show {
      opacity: 1;
      visibility: visible;
    }

    @media only screen and (max-width: 600px) {
      margin-top: 40px;
    }

    .loyal__app__button {
      margin-left: 10px;

      @media only screen and (max-width: 600px) {
        margin: 0;
      }
    }
  }
}

@keyframes loyal__app__project__step__bar__show {
  from {
    left: -100%;
  }

  to {
    top: 0;
  }
}

@keyframes loyal__app__project__input__show {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(50%);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

@keyframes loyal__app__project__input__hide {
  from {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-50%);
  }
}