.loyal__app__reset__password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #375AA8;
  height: 100vh;
  max-height: -webkit-fill-available;

  .form {
    width: 100%;
    max-width: 480px;
    padding: 20px;
    box-sizing: border-box;
    align-self: center;

    input,
    button {
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #fff;
      margin: 5px 0;
      font-size: 14px;
      font-weight: bold;
    }

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: #ddd;
    }
  }

  .success {
    margin: 20px;
    background-color: white;
    color: green;
    border-radius: 5px;
    padding: 10px;
    font-weight: bold;
    text-align: center;
  }

  .error {
    margin: 20px 0;
    background-color: red;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-weight: bold;
    text-align: center;
  }
}