.loyal__app__logo {
  position: absolute;
  top: 3rem;
  left: 7rem;
  height: 35px;
  width: 120px;
  overflow: hidden;
  cursor: pointer;
  z-index: 1000;

  &__white {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 1s ease;
  }

  &__blue {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 1s ease;
  }

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 35px;
    animation: loyal__app__logo__inner__show 1s forwards ease;
  }

  &.blue {
    .loyal__app__logo__white {
      opacity: 0
    }

    .loyal__app__logo__blue {
      opacity: 1
    }
  }
}

@keyframes loyal__app__logo__inner__show {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .loyal__app__logo {
    display: none;
  }
}