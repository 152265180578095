.loyal__app__button {
  position: relative;
  width: 115px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid white;
  overflow: hidden;
  transition: all .3s cubic-bezier(1, 0, .5, .8);
  cursor: pointer;

  &.scroll:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 4px;
    height: 10px;
    border-radius: 2px;
    background: white;
    transition: all 1s ease;
    animation: loyal__app__scroll__thumbnail 1s ease infinite;
  }

  &__inner {
    transition: all .3s ease;
  }

  &__word {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 12px;
    height: 40px;
    overflow: hidden;
  }

  &__letter {
    letter-spacing: .1rem;
    animation: loyal__app__button__letter 3s infinite cubic-bezier(1, 0, .5, .8);
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.right {
      right: .8rem;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        border-right: 2px solid white;
        border-bottom: 2px solid white;
        width: 4px;
        height: 4px;
        transition: all .3s cubic-bezier(1, 0, .5, .8);
        transform: translateY(-50%) rotate(-45deg);
      }
    }

    &.left {
      left: .8rem;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        border-left: 2px solid white;
        border-top: 2px solid white;
        width: 4px;
        height: 4px;
        transition: all .3s cubic-bezier(1, 0, .5, .8);
        transform: translateY(-50%) rotate(-45deg);
      }
    }

    &.bottom {
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        border-left: 2px solid white;
        border-bottom: 2px solid white;
        width: 4px;
        height: 4px;
        transition: all .3s cubic-bezier(1, 0, .5, .8);
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }

  &.switch:hover {
    @media only screen and (min-width: 600px) {
      &:after {
        transform: translateX(-100px);
        animation: unset;
      }

      .loyal__app__button__inner {
        transform: translateY(-50%);
      }
    }
  }
}

@keyframes loyal__app__button__letter {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-100px);
    opacity: 0;
  }

  21% {
    opacity: 0;
    transform: translateX(100px);
  }

  22% {
    opacity: 1;
  }

  40% {
    transform: translateX(0);
  }
}

@keyframes loyal__app__scroll__thumbnail {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 1;
    transform: translateY(20px);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}