.loyal__app__about {
  &__right__panel {
    padding-left: 40vw;
    padding-right: 2rem;
    height: calc(var(--vh, 1vh) * 100);

    @media only screen and (max-width: 600px) {
      padding: 2rem;
      padding-bottom: 20rem;
      height: auto;
    }

    &__top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: calc(var(--vh, 1vh) * 100);

      @media only screen and (max-width: 600px) {
        height: auto;
      }
    }

    &__header {
      display: flex;
      flex-wrap: wrap;
      font-family: MonumentExtended-Regular;
      font-size: 2.5rem;
      line-height: 2.5rem;
      letter-spacing: .15rem;

      @media only screen and (max-width: 600px) {
        font-size: 1.5rem;
        line-height: 1.5rem;
        letter-spacing: .1rem;
      }
    }

    &__text {
      font-size: 1.12rem;
      margin-top: 2rem;
      color: #636363;
    }

    &__column {
      display: flex;
      margin-top: 2rem;

      @media only screen and (max-width: 600px) {
        flex-wrap: wrap;

        >div {
          margin-top: 2rem;
        }
      }

      >div {
        &:first-child {
          flex-basis: 250px;
          flex-shrink: 0;
        }
      }

      &__title {
        font-size: 1.5rem;
        margin-bottom: 2rem;
      }

      &__text {
        font-size: 1.12rem;
        line-height: 2rem;
        color: #636363;

        .bullets {
          position: relative;
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 3px;
          background: white;
          margin-right: .5rem;
          border: 1px solid #ccc;

          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            height: 6px;
            width: 6px;
            border-radius: 1px;
            transform: translate(-50%, -50%);
            background: #636363;
          }
        }
      }

      &__long__text {
        display: flex;
        flex-wrap: wrap;
        font-size: 1.12rem;
        line-height: 2rem;
        color: #636363;
      }
    }
  }
}