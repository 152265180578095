.loyal__app__intro {
  &__left__panel {
    position: absolute;
    margin-left: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(var(--vh, 1vh) * 100);
    max-width: 500px;
    color: white;
  }

  &__right__panel {
    position: absolute;
    top: 0;
    right: -1px;
    transform: translateX(200%);

    &.active {
      animation: loyal__app__intro__panel 1s ease forwards;

      .loyal__app__intro__right__panel__inner:after {
        animation: loyal__app__intro__skew 1s ease forwards;
      }
    }

    &__inner {
      height: calc(var(--vh, 1vh) * 100);
      width: 20vw;
      background: white;
      transition: all 1s ease;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: -50%;
        background: inherit;
        width: 100%;
        height: 100%;
        transform: skewX(-10deg);
      }

      &.skew {
        transform: translateX(100%);

        &:after {
          animation: loyal__app__intro__skew__hide 3s ease forwards !important;
        }
      }
    }
  }

  &__header {
    font-family: MonumentExtended-Regular;
    font-size: 3rem;
    line-height: 3rem;
    letter-spacing: .15rem;
  }

  &__word {
    margin-top: 1rem;
    letter-spacing: .1rem;
    line-height: 2rem;
  }

  &__button {
    position: absolute;
    bottom: 5rem;
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 65vw;
    transform: translateY(-50%);
    z-index: 1;
    transition: all 1s cubic-bezier(1, 0, .5, .8);

    &.next__2 {
      left: 10rem;
      transform: translateY(-50%) scale(1.2);
      animation: loyal__app__intro__image__next 1s ease forwards;
      // z-index: 0;
    }

    &.next__3 {
      left: -65vw;
    }

    &.next__to {
      left: -65vw;
    }

    &__inner {
      transform: translate(200%, -50%);
      animation: loyal__app__intro__image 1s .2s ease forwards;
    }
  }

  &__image__mobile {
    display: none;
  }
}

@keyframes loyal__app__intro__panel {
  from {
    transform: translateX(200%);
  }

  to {
    transform: translateX(0)
  }
}

@keyframes loyal__app__intro__skew {
  from {
    transform: skewX(0deg);
  }

  to {
    transform: skewX(-10deg);
  }
}

@keyframes loyal__app__intro__skew__hide {
  from {
    transform: skewX(-10deg);
  }

  to {
    transform: skewX(10deg);
  }
}

@keyframes loyal__app__intro__image {
  from {
    transform: translate(200%, -50%);
  }

  to {
    transform: translate(0, 0)
  }
}

@keyframes loyal__app__intro__image__next {
  99% {
    z-index: 1;
  }

  100% {
    z-index: 0;
  }
}

@media only screen and (max-width: 600px) {
  .loyal__app__intro {
    font-size: .8em;
    text-align: center;

    &__header {
      font-size: 2rem;
      line-height: 2rem;
    }

    &__left__panel {
      position: relative;
      display: block;
      width: 100%;
      max-width: 100%;
      margin-left: 0;
      padding: 4rem 2rem;
      box-sizing: border-box;
      height: unset;
    }

    &__right__panel,
    &__image {
      display: none;
    }

    &__button {
      right: 2rem;
    }

    .loyal__app__button {
      &.scroll {
        &:after {
          display: none;
        }

        .loyal__app__button__word:first-child {
          display: none;
        }
      }
    }

    &__image__mobile {
      display: flex;
      justify-content: center;
      margin: 50px 0;
    }
  }

  .loyal__app__text__word {
    justify-content: center;
  }
}

@media only screen and (max-width: 900px) {
  .loyal__app__intro {

    &__right__panel,
    &__image {
      opacity: 0;
    }
  }
}