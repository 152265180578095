html,
body {
  margin: 0;
  padding: 0;
  font-family: Gilmer-Regular, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  background: white;
  overflow: hidden;
  scroll-behavior: smooth;
  user-select: none;
  height: calc(var(--vh, 1vh) * 100);
}

img {
  user-select: none;
}

::-webkit-scrollbar {
  display: none;
}

::selection {
  background: white;
  color: #25054d;
}