.loyal__app__topbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background: white;
  border-bottom: 1px #edf2f7 solid;
  transition: all .3s ease;
  transform: translateY(-100%);
  padding: 0 .5rem 0 1rem;
  box-sizing: border-box;
  z-index: 1000;

  @media only screen and (max-width: 600px) {
    animation: loyal__app__topbar__show 1s ease-in-out forwards;
  }

  &__menu {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transform: scale(.8);

    &__dot {
      margin: 3px;
      width: 5px;
      height: 5px;
      border-radius: 1.5px;
      background: #1a1a1a;
      transition: all .3s ease;
    }

    &__animate {
      position: relative;
      width: 5px;
      height: 5px;
      margin: 3px;
      transition: all .3s ease;

      .loyal__app__topbar__menu__dot {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
      }
    }

    &.open {
      .loyal__app__topbar__menu__animate {
        &:first-child {
          .loyal__app__topbar__menu__dot {
            &:first-child {
              transform: translate(-7px, 4px);
            }

            &:last-child {
              transform: translate(7px, 4px);
            }
          }
        }

        &:last-child {
          .loyal__app__topbar__menu__dot {
            &:first-child {
              transform: translate(-7px, -4px);
            }

            &:last-child {
              transform: translate(7px, -4px);
            }
          }
        }
      }
    }
  }
}

@keyframes loyal__app__topbar__show {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}