.loyal__app__admin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background: white;
  cursor: default;

  &__action__bar {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 10px;
    background: white;
    box-sizing: border-box;
    border-bottom: 1px solid #eee;
    z-index: 2;

    .button__back {
      margin-left: 5px;
    }
  }

  &__action__button {
    font-size: 14px;
    line-height: 20px;
    padding: 0 5px;
    background: #fcfff4;
    background: linear-gradient(to bottom, #ffffff 0%, #e9e7f5 40%, #fff 100%);
    border-radius: 4px;
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    margin-right: 10px;

    &.disabled {
      opacity: .5;
    }
  }

  &__messages {
    display: flex;
    border-top: 1px solid #eee;
  }

  &__message__content {
    .top {
      position: sticky;
      top: 50px;
      z-index: 2;
      background: white;
      padding: 1rem;
      border-bottom: 1px solid #eee;

      .subject {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;

        span {
          margin-left: 5px;
        }
      }

      .company {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: bold;

        span {
          margin-left: 5px;
        }
      }

      .sender {
        font-size: 12px;
        margin-top: 10px;

        a {
          color: black !important;
          text-decoration: none;
        }

        span {
          font-weight: bold;
        }
      }
    }

    .infobar {
      display: flex;
      position: sticky;
      top: 50px;
      z-index: 2;
      background: white;
      padding: 1rem;
      border-bottom: 1px solid #eee;

      >div {
        flex: 0 0 50%;
      }

      .badge {
        display: flex;
        flex-wrap: wrap;
        min-height: 85px;
        align-items: center;

        span {
          padding: 0 1rem;
          background: #25054d;
          color: white;
          height: 2rem;
          line-height: 2rem;
          border-radius: 3rem;
          font-size: 14px;
          font-weight: bold;
          margin-right: 5px;
          margin-bottom: 5px;
        }
      }

      .title {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .budget {
        display: flex;
        font-size: 1.5rem;
        font-weight: bold;

        >div {
          margin: 0 5px;
        }

        span {
          font-size: 14px;
        }
      }
    }

    .message {
      padding: 1rem;
    }
  }

  &__left__panel {
    flex: 0 0 300px;
    height: calc(var(--vh, 1vh) * 100);
    border-right: 1px solid #eee;

    &__menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      font-size: 14px;
      font-weight: bold;
      line-height: 2.5rem;
      cursor: pointer;

      .icon {
        width: 15px;

        .loyal__app__image {
          margin: auto;
        }
      }

      span {
        flex-grow: 1;
        margin: 0 10px;
      }

      &.active {
        background: #f5f5f5;
        font-weight: bold;
      }

      .loyal__app__admin__action__button {
        width: 100%;
        margin: 10px 0;
        text-align: center;
        line-height: 30px;
      }
    }

    &__notification {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      min-width: 12px;
      border-radius: 3px;
      font-size: 12px;
      font-weight: bold;
      padding: 0 5px;
      border: 1px solid #ddd;

      &.active {
        border: none;
        background: #d30002;
        color: white;
      }
    }
  }

  &__right__panel {
    flex-grow: 1;
    height: calc(var(--vh, 1vh) * 100);
    overflow: auto;
  }

  &__table {
    width: 100%;
    border: 0;

    th {
      position: sticky;
      top: 50px;
      font-size: 12px;
      font-weight: bold;
      line-height: 30px;
      text-align: left;
      background: #f5f5f5;
      padding: 0 10px;
      box-sizing: border-box;
      z-index: 2;

      &:first-child {
        padding-left: 40px;
      }
    }

    td {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 10px;
      box-sizing: border-box;
      border-bottom: 1px solid #eee;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;

      >div {
        width: 100%;
        display: inline-flex;
        align-items: center;

        div {
          flex-shrink: 0;
        }
      }

      .loyal__app__image {
        display: inline-block;
        margin-right: 5px;
      }

      span {
        line-height: 40px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .star__active {
        display: flex;
      }

      .star {
        display: flex;
        opacity: .5;

        &:hover {
          opacity: 1;
        }
      }
    }

    tr {
      font-size: 13px;
      line-height: 30px;

      &:hover td {
        background: #eee !important;
      }

      &.unread td {
        font-weight: bold;
        background: #f9f9f9;
      }

      &.remove {
        color: #d30002;
      }
    }
  }

  &__delete__forever {
    border-radius: 3px;
    padding: 0 10px;
    transition: all .1s ease;

    &:hover {
      background: #d30002;
      color: white;
    }
  }

  .loyal__app__checkbox {
    margin-right: 1rem;
  }

  &__login__box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    width: 400px;
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
    border-radius: 20px;

    .loyal__app__image {
      margin: auto;
    }

    input {
      width: 100%;
      box-sizing: border-box;
      line-height: 30px;
      padding: 0 10px;
      margin: 10px 0;
      font-weight: bold;
      text-align: center;
      border: 1px solid #ddd;
      border-radius: 5px;
    }

    .loyal__app__admin__action__button {
      width: 100%;
      box-sizing: border-box;
      margin: 10px 0;
      text-align: center;
      font-weight: bold;
      line-height: 30px;
    }
  }

  &__tabs {
    display: flex;
    border-bottom: 1px solid #eee;
  }

  &__tab {
    position: relative;
    font-size: 1rem;
    font-weight: bold;
    line-height: 2rem;
    padding: .5rem 4rem;
    cursor: pointer;

    .notification {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      min-width: 12px;
      border-radius: 3px;
      font-size: 12px;
      font-weight: bold;
      padding: 0 5px;
      background: #d30002;
      color: white;

      &.starred,
      &.trash {
        background: transparent;
        color: black;
        border: 1px solid #ddd;
      }
    }

    &.active::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: #25054d;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      z-index: 2;
    }
  }
}