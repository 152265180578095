.loyal__app__menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background: white;
  padding-left: 4rem;
  box-sizing: border-box;
  z-index: 100;
  transition: all 1s ease;
  opacity: 0;
  visibility: hidden;

  @media only screen and (max-width: 600px) {
    padding-left: 0;
    overflow: auto;
  }

  &.open {
    opacity: 1;
    visibility: visible;
    transition: all .5s ease;

    .company {
      height: 85%;
    }
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #f5f5f5;
    width: 100vw;
    height: 100%;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
      ;
    }

    .company {
      position: absolute;
      right: 10px;
      bottom: 0;
      width: 50vw;
      height: 0%;
      font-weight: bold;
      box-sizing: border-box;
      transition: all 1s ease;

      .map {
        height: 60vh;
        width: 100%;
        margin-top: 20px;
        background: #eee;
      }

      @media only screen and (max-width: 600px) {
        right: 0;
        width: 100vw;
        position: relative;

        h2 {
          font-size: 20px;
        }

        .info {
          padding: 15px 35px 35px 35px;
          border-top: 1px solid #ddd;
          background: #25054d;
          color: white;
        }

        .map {
          margin-top: 0;
        }
      }
    }
  }

  &__link {
    margin-left: 7vw;
    font-size: 7vh;
    line-height: 10vh;
    z-index: 2;

    @media only screen and (max-width: 600px) {
      margin-left: 0;
      font-size: 4vh;
      line-height: 7vh;
      padding: 2rem;
      margin-top: 4rem;
    }
  }

  .loyal__app__map__point {
    position: relative;
    display: inline-block;
    padding: 5px;
    background: white;
    border-radius: 5px;
    transform: translate(-50%, -100%);

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top-color: white;
      border-bottom: 0;
      margin-left: -10px;
      margin-bottom: -10px;
    }

    span {
      display: none;
    }
  }

  .loyal__app__login__button {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 2;
    transform: scale(.8);

    .loyal__app__button {
      border-color: black;

      .loyal__app__button__arrow:after {
        border-color: black;
      }

      .loyal__app__button__word {
        color: black;
        font-size: 16px;
      }
    }
  }

}